import { FieldArray, useField, useFormikContext } from "formik";
import { FormikCheckboxField, FormikNumberField } from "@superdispatch/forms";
import { Stack, Inline } from "@superdispatch/ui";
import { Divider, IconButton } from "@material-ui/core";
import { Delete, Add } from "@material-ui/icons";
import { Button, TextBox } from "@superdispatch/ui-lab";
import styled from "styled-components";
import { VehicleMakeAutocompleteField } from "./VehicleMakeAutocompleteField";
import { VehicleModelAutocompleteField } from "./VehicleModelAutocompleteField";
import {
  CarrierPayVehicleDTO,
  carrierPayVehicleSchema,
  generateVehicleKey,
} from "../../../data/ShippingDetailsDTO";
import { VehicleTypeField } from "./VehicleTypeField";
import { addMonitoringAction } from "shared/services/MonitoringService";

const FieldsContainer = styled.div`
  display: grid;
  grid-column-gap: 8px;
  grid-template-columns: 100px 114px 176px 124px 34px;

  @media (max-width: 450px) {
    grid-row-gap: 8px;
    grid-template-columns: 1fr;
  }

  @media (min-width: 451px) and (max-width: 1340px) {
    grid-row-gap: 8px;
    grid-template-columns: repeat(3, minmax(80px, 1fr));

    & :nth-child(2) {
      grid-column: 2 / span 3;
    }
    & :nth-child(3) {
      grid-column: 1 / span 2;
    }

    & :nth-child(5) {
      grid-row: 3;
    }
  }
`;

function VehicleItemField({
  index,
  name,
  onRemove,
  showDivider,
  vehicle,
}: {
  index: number;
  vehicle: CarrierPayVehicleDTO;
  showDivider: boolean;
  name: string;
  onRemove: () => void;
}) {
  return (
    <Stack>
      {showDivider && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 16,
            margin: "2px 0",
          }}
        >
          <TextBox color="secondary" variant="body-semibold">
            #{index + 1}
          </TextBox>
          <Divider style={{ flexGrow: 1 }} />
          <IconButton onClick={onRemove}>
            <Delete />
          </IconButton>
        </div>
      )}

      <FieldsContainer>
        <FormikNumberField
          name={`${name}.year`}
          inputMode="numeric"
          label={
            <Inline noWrap={true} space="xxsmall">
              <TextBox>Year</TextBox>
              <TextBox color="secondary">(Required)</TextBox>
            </Inline>
          }
          inputProps={{
            decimalScale: 0,
            thousandSeparator: false,
            allowNegative: false,
            allowLeadingZeros: false,
          }}
        />

        <VehicleMakeAutocompleteField name={`${name}.make`} />

        <VehicleModelAutocompleteField
          name={`${name}.model`}
          make={vehicle.make}
        />

        <VehicleTypeField
          name={`${name}.type`}
          make={vehicle.make}
          model={vehicle.model}
        />

        <Stack space="xxsmall">
          <TextBox>INOP</TextBox>
          <FormikCheckboxField name={`${name}.is_inoperable`} label="" />
        </Stack>
      </FieldsContainer>
    </Stack>
  );
}

export function VehiclesField({ name }: { name: string }) {
  const { isSubmitting } = useFormikContext();

  const [
    {
      value = [
        carrierPayVehicleSchema.cast({
          model: null,
          make: null,
          type: null,
        }),
      ],
    },
  ] = useField<CarrierPayVehicleDTO[]>(name);
  return (
    <FieldArray name={name}>
      {({ remove, push }) => {
        return (
          <Stack space="small">
            <Stack space="medium">
              {value.map((item, index) => (
                <VehicleItemField
                  key={item.$key}
                  vehicle={item}
                  index={index}
                  showDivider={value.length > 1}
                  name={`${name}.${index}`}
                  onRemove={() => {
                    remove(index);
                  }}
                />
              ))}
            </Stack>

            {value.length <= 10 && (
              <Button
                variant="text"
                startIcon={<Add />}
                disabled={isSubmitting}
                onClick={() => {
                  push({
                    type: null,
                    model: null,
                    make: null,
                    year: null,
                    $key: generateVehicleKey(),
                  });
                  addMonitoringAction("add_vehicle");
                }}
              >
                Add Vehicle
              </Button>
            )}
          </Stack>
        );
      }}
    </FieldArray>
  );
}
